import React from "react";
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { above } from '../styles/utilities';
import Layout from "../layouts/default";
// import SEO from "../components/seo";
// import img from '../images/heroes/eye-exams-hero.jpg';
import Hero from '../components/Hero';
import Location from '../components/Location';
import Footer from '../components/Footer';

const NotFoundPage = props => {

  const hero = {
    image: props.data.heroImg,
    heading: "Page Not Found",
    text: "",
    button_link: "https://www.lenscrafters.com/ScheduleExamView?catalogId=11651&clearExams=1&langId=-1&storeNumber=0295&storeId=10851&cid=Yext_PagesSchedule_LCOD0295",
    button_text: "Schedule an Appointment",
    link_type: "external"
  };

  const Content = props => (
    <section className="container main-content">
      <main>
        <h1>Not Found</h1>
        <p>We're sorry, the page you are looking for could not be found.</p>
      </main>
    </section>
  );

  return (
    <Layout className={props.className} menu={props.data.menu} meta={props.data.site.siteMetadata}>
      <Hero
        className="hero--default"
        menu={props.data.menu}
        hero={hero}
        phoneNumber={props.data.site.siteMetadata.phoneNumber}
      />
      <Content />
      <Location location={props.data.location}></Location>
      <Footer menu={props.data.footerMenu}></Footer>
    </Layout>
  )
}

export default styled(NotFoundPage)`
  .main-content {
    padding-bottom: 75px;
    padding-top: 75px;
    text-align: center;

    ${above.md`
      text-align: left;
    `}
  }
`;

export const query = graphql`
  {

    site {
      siteMetadata {
        phoneNumber
      }
    }

    heroImg: file(name: {eq: "eye-exams-hero"}) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    menu: markdownRemark(
      frontmatter: {
        type: {
          eq: "menu"
        },
      }) {
      html
      frontmatter {
        menu
      }
    }

    location: markdownRemark(
      frontmatter: {
        name: {
          eq: "location"
        }
      }) {
      html
      frontmatter {
        button_link
        button_text
        map
      }
    }

    footerMenu: markdownRemark(
      frontmatter: {
        type: {
          eq: "menu"
        },
        name: {
          eq: "footer-menu"
        }
      }) {
        html
        frontmatter {
          menu
        }
    }

  }
`;